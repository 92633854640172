export default {
  base_url: process.env.REACT_APP_BASE_URL + "api",
  image_base_url: process.env.REACT_APP_BASE_URL,
  date_format: process.env.REACT_APP_DATE_FORMAT,
  sms_date_format: process.env.REACT_APP_SMS_DATE_FORMAT,
  display_date_format: process.env.REACT_APP_DISPLAY_DATE_FORMAT,
  display_datetime_format: process.env.REACT_APP_DISPLAY_DATETIME_FORMAT,
  date_format_cal: process.env.REACT_APP_DATE_FORMAT_CAL,
  sms_type: process.env.REACT_APP_SMS_TYPE,
  db_date_format: process.env.REACT_APP_DB_DATE_FORMAT,
  environment: process.env.REACT_APP_ENVIRONMENT,
  ads: process.env.REACT_APP_ADS,
  features: {
    auto_otp_read: process.env.REACT_APP_FEATURE_AUTO_OTP_READ,
  },
  amplitude_key: process.env.REACT_APP_AMPLITUDE_KEY,
};
