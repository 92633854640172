// Intercept and refresh expired tokens for multiple requests (same implementation but with some abstractions)
//
// HOW TO USE:
import axiosIntercepter from "./axios.intercepter.js";
import axios from "axios";
import config from "../../../config";

const apiClient = axios.create({
  baseURL: config.base_url,
});

axiosIntercepter(apiClient); // register the interceptor with one specific axios instance

export default apiClient;
