import React, { useState } from "react";
import { useLanguage } from "../../../../language/LanguageController";
import { Switch } from "@material-ui/core";

const MilkRateKgPerFat = ({ isKgPerFat }) => {
    const { t } = useLanguage();
    const [isKgPerFatEnabled, setIsKgPerFatEnabled] = useState(isKgPerFat);

    return (
        <div className="form-row">
            <div className="col-7">
                <label>{t("text_allow_milk_rate_kg_per_fat")}</label>
            </div>
            <div className="col-5 text-right">
                <Switch
                    color="default"
                    name="is_kg_per_fat"
                    checked={isKgPerFatEnabled == "1"}
                    onChange={e => setIsKgPerFatEnabled(e.target.checked ? 1: 0)}
                    value={isKgPerFatEnabled ? 1 : 0}
                />
            </div>
        </div>
    );
};

export default MilkRateKgPerFat;
