import React from 'react';
import { InputText, InputCheckbox, InputTextMobileCode } from '../../../core/input';
import { Radio, Icon } from 'react-onsenui';
import Tooltip from 'react-power-tooltip';
import Select from "react-select";
import withLanguage from '../../../language/LanguageController';
import { sanitizeMobileNumber } from '../../../library/util';
import { errorHandler } from '../../../library/response';
import { checkFeatureInSubscription } from '../../../common/FeatureFlag/FeatureFlag';
import { connect } from 'react-redux';
import { togglePremiumPopup } from "../../../../actions";
import { contactPicker } from '../../../library/contactPicker';
import SelectContactModal from './SelectContactModal';
import { getCustomerCodeError } from '../../../library/customer';
import { isAndroid } from 'src/components/library/detect-device';
import DeleteIcon from "@material-ui/icons/Delete";
import { RedButton } from '../../../common/buttons/RedButton';
import { CUSTOMER_TYPE_BUYER, CUSTOMER_TYPE_SELLER } from '../../../../_constants';
import MilkAnimalSetting from './MilkAnimalSetting';

const mobileOptions = {
    messages: {
        "required": "the_mobile_number_field_is_required.",
        "numeric": "the_mobile_number_must_be_a_valid_phone_number.",
        "size": "the_mobile_number_must_be_a_valid_phone_number.",
    }
};
const nameOptions = {
    messages: {
        "required": "the_customer_name_field_is_required.",
        "alpha_num_space": "the_name_may_only_contain_letters_num_and_spaces."
    }
};
const cowRateOptions = {
    messages: {
        "required": "the_rate_field_is_required.",
        "min": "the_cow_rate_must_be_atleast_1."
    }
};
const buffaloRateOptions = {
    messages: {
        "required": "the_rate_field_is_required.",
        "min": "the_buffalo_rate_must_be_atleast_1."
    }
};
const milkAnimalOptions = {
    messages: {
        "required": "the_milk_animal_field_is_required."
    }
};
const milkAnimalQuantity = {
    messages: {
        "required": "the_milk_animal_quantity_is_required.",
        "min" : "the_milk_quantity_must_be_atleast_1."
    }
};

class AddCustomerBox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            numberData: [],
            autosuggest: false,
            mobile: "",
            name: "",
            code: props.latestCode,
            customer_id: "",
            sellerHelp: false,
            buyerHelp: false,
            personal_rate_cow: false,
            personal_rate_buffalo: false,
            milk_animal_cow: false,
            milk_animal_buffalo: false,
            is_buyer:false,
            fixed_buyer_cow: false,
            fixed_buyer_buffalo: false,
            isOpenSelectContact: false,
            contact: {}
        };
        this.openAddressBook = this.openAddressBook.bind(this);
    }

    componentDidMount() {
        this.props.onCodeChange(this.props.index, this.props.latestCode);
    }

    async openAddressBook(e) {
        try {
            e.stopPropagation()
            e.preventDefault();
            const contact = await contactPicker();
            if (contact) {
                if (!contact.phoneNumbers) {
                    throw this.props.t("text_selected_contact_not_have_number");
                }
                if (contact.phoneNumbers.length > 1) {
                    this.setState({
                        isOpenSelectContact: true,
                        contact: contact
                    });
                } else {
                    this.setState({
                        mobile: sanitizeMobileNumber(contact.phoneNumbers[0].value),
                        name: contact.displayName,
                    })
                }
            }
        } catch (error) {
            errorHandler(error);
        }
    }

    onContactSelected = (number) => {
        this.setState({
            isOpenSelectContact: false,
            mobile: sanitizeMobileNumber(number),
            name: this.state.contact.displayName,
        });
    }

    hideSelectContact = () => {
        this.setState({
            isOpenSelectContact: false
        });
    }

    handleInput = (e) => {
        let target = e.target,
            value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value,
            name = target.name,
            dName = target.getAttribute("data-name");
        name = dName ? dName : name;

        const stateData = {
            [name]: value
        };
        if (["milk_animal_buffalo", "milk_animal_cow"].indexOf(name) > -1) {
            if (!checkFeatureInSubscription("multiple_cattle", false) && value) {
                if ((name === "milk_animal_buffalo" && this.state.milk_animal_cow === true)
                    || (name === "milk_animal_cow" && this.state.milk_animal_buffalo === true)) {
                    target.checked = false;
                    this.props.togglePremiumPopup({
                        feature: "multiple_cattle"
                    });
                    return false;
                }
            }
        }
        this.setState(stateData, () => {
            if (name == "code") {
                this.props.onCodeChange(this.props.index, value);
            }
        });
    }

    personalRateChangeHandler = e => {
        const name = e.target.getAttribute('data-name');
        let checked = e.target.checked;
        if (!checkFeatureInSubscription("personal_rate")) {
            checked = false
        }
        this.props.validator.purgeFields();
        this.setState({
            [name]: checked
        });
    }

    showHelp = (e, type) => {
        let stateData = {
            sellerHelp: false,
            buyerHelp: false
        };
        stateData[type + "Help"] = !this.state[type + "Help"];
        this.setState(stateData);
    }

    getExistingCodes = () => {
        const existingCodes = [];
        for (const codeKey in this.props.allCodes) {
            if (codeKey != this.props.index) {
                existingCodes.push(this.props.allCodes[codeKey]);
            }
        }
        return existingCodes;
    }

    customerTypeHandler = (e) => {
        const customerType = e.target.value;
        this.setState({
            is_buyer: customerType === CUSTOMER_TYPE_BUYER ? true : false,
            fixed_buyer_cow: customerType === CUSTOMER_TYPE_BUYER ? this.state.fixed_buyer_cow : false,
            fixed_buyer_buffalo: customerType === CUSTOMER_TYPE_BUYER ? this.state.fixed_buyer_buffalo : false,
        });
    }

    render() {
        const { t, index, rateLists, isLast } = this.props;
        const { personal_rate_cow, personal_rate_buffalo, milk_animal_buffalo, milk_animal_cow, fixed_buyer_cow, fixed_buyer_buffalo } = this.state;
        const existingCodes = this.getExistingCodes();
        return (
            <>
                <div className={`t-card shadow-sm w-100 ${isLast ? "mb-2" : "mb-2"}`}>
                    <div className="position-relative">
                        {index > 0 && <RedButton
                            className="position-absolute right-0 z-index-999"
                            onClick={e => this.props.removeCustomer(e, this.props.index)}>
                            <DeleteIcon />
                        </RedButton>}
                        <div className="form-group row">
                            <label className="col-md-12">{t("text_customer_is")} ?</label>
                            <div className="col-md-12">
                                <div className="form-check form-check-inline min-width130">
                                    <Radio
                                        modifier="material form-check-input"
                                        name={"customers[" + this.props.index + "][type]"}
                                        inputId={"type_seller_" + this.props.index}
                                        value={CUSTOMER_TYPE_SELLER}
                                        onChange={this.customerTypeHandler}
                                        defaultChecked />
                                    <label
                                        htmlFor={"type_seller_" + this.props.index}
                                        className="form-check-label">
                                        {t("text_seller")} &nbsp;
                                        <Icon icon="md-help" onClick={e => this.showHelp(e, 'seller')} />
                                        <Tooltip
                                            show={this.state.sellerHelp}
                                            position="bottom center">
                                            <span>{t("text_seller_help")}</span>
                                        </Tooltip>
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <Radio
                                        modifier="form-check-input"
                                        name={"customers[" + this.props.index + "][type]"}
                                        inputId={"type_buyer_" + this.props.index}
                                        onChange={this.customerTypeHandler}
                                        value={CUSTOMER_TYPE_BUYER} 
                                    />
                                    <label
                                        htmlFor={"type_buyer_" + this.props.index}
                                        className="form-check-label">
                                        {t("text_buyer")} &nbsp;
                                        <Icon icon="md-help" onClick={e => this.showHelp(e, 'buyer')} />
                                        <Tooltip
                                            show={this.state.buyerHelp}
                                            position="bottom center">
                                            <span>{t("text_buyer_help")}</span>
                                        </Tooltip>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <InputTextMobileCode
                            label={t("mobile_number")}
                            className="form-control add_customer_input"
                            type="number"
                            data-name="mobile"
                            id={`customer_mobile_${this.props.index}`}
                            name={"customers[" + this.props.index + "][mobile]"}
                            value={this.state.mobile}
                            errorMessage={this.props.validator.message(`mobile_number_${index}`, this.state.mobile, 'numeric|size:10,string', mobileOptions)}
                            onChange={this.handleInput}
                            addressBook={isAndroid()}
                            onAddressBook={this.openAddressBook} />
                        <div className="form-group">
                            <InputText
                                label={t("text_customer_name")}
                                className="form-control add_customer_input"
                                type="text"
                                data-name="name"
                                placeholder={t("text_enter_customer_name")}
                                name={"customers[" + this.props.index + "][name]"}
                                value={this.state.name}
                                errorMessage={
                                    this.props.validator.message(`customer_name_${index}`, this.state.name, 'required|alpha_num_space', nameOptions)
                                }
                                onChange={this.handleInput} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="customer_code">{t("text_code")}</label>
                            <input type="number"
                                className="form-control add_customer_input"
                                name={"customers[" + this.props.index + "][code]"}
                                data-name="code"
                                id={`customers_${this.props.index}_code`}
                                placeholder={t("text_enter_customer_code")}
                                defaultValue={this.state.code}
                                onChange={this.handleInput} />
                            {getCustomerCodeError(this.props.validator, this.state.code, existingCodes)}
                        </div>
                        {rateLists.length > 0 && <div className="form-group">
                            <label>{t("text_rate_list")}</label>
                            <Select
                                placeholder={t("text_select_rate_list") + "..."}
                                options={rateLists}
                                isSearchable={false}
                                isClearable
                                name={"customers[" + this.props.index + "][rate_list_id]"}
                                className="add_customer_rate_list_select z-index-1001i"
                                maxMenuHeight={200}
                            />
                        </div>}
                        <div className="form-group row">
                            <label className="col-md-12">
                                {t("text_animals")}:
                            </label>
                            <div className="col-md-12">
                                <div className="form-row clearfix">
                                    <div className="col-2">
                                    <div className="form-check form-check-inline">
                                        <InputCheckbox
                                        modifier="form-check-input"
                                        name={`customers[${this.props.index}][milk_animals][]`}
                                        id={`milk_animal_cow_${this.props.index}`}
                                        value="cow"
                                        data-name="milk_animal_cow"
                                        checked={this.state.milk_animal_cow}
                                        onChange={this.handleInput}
                                        />
                                        <label htmlFor={`milk_animal_cow_${this.props.index}`} className="form-check-label">
                                        {t("text_cow")}
                                        </label>
                                    </div>
                                    </div>
                                    {this.state.milk_animal_cow && <MilkAnimalSetting
                                        animalType="cow"
                                        isBuyer={this.state.is_buyer}
                                        personalRateChecked={personal_rate_cow || fixed_buyer_cow}
                                        fixedBuyerChecked={fixed_buyer_cow}
                                        onSwitchChange={this.personalRateChangeHandler}
                                        isEdit={false}
                                        handleInput={this.handleInput}
                                        validator={this.props.validator}
                                        index={this.props.index}
                                        quantityValue={(this.state.cow_liter_morning || this.state.cow_liter_evening) ? 'true' : ''}
                                        quantityMessage={milkAnimalQuantity}
                                        rateValue={this.state.cow_rate}
                                        rateValidatorMessage={cowRateOptions}
                                    />}
                                </div>
                            </div>
                            <div className="col-md-12 mt-2">
                                <div className="form-row">
                                    <div className="col-2">
                                        <div className="form-check form-check-inline">
                                            <InputCheckbox
                                                modifier="form-check-input"
                                                name={"customers[" + this.props.index + "][milk_animals][]"}
                                                id={"milk_animal_buffalo_" + this.props.index}
                                                value="buffalo"
                                                data-name="milk_animal_buffalo"
                                                checked={milk_animal_buffalo}
                                                onChange={this.handleInput} />
                                            <label htmlFor={`milk_animal_buffalo_${index}`} className="form-check-label">{t("text_buffalo")}</label>
                                        </div>
                                    </div>
                                    {this.state.milk_animal_buffalo && <MilkAnimalSetting
                                        animalType="buffalo"
                                        isBuyer={this.state.is_buyer}
                                        personalRateChecked={personal_rate_buffalo || fixed_buyer_buffalo}
                                        fixedBuyerChecked={fixed_buyer_buffalo}
                                        onSwitchChange={this.personalRateChangeHandler}
                                        isEdit={false}
                                        handleInput={this.handleInput}
                                        validator={this.props.validator}
                                        index={this.props.index}
                                        quantityValue={(this.state.buffalo_liter_morning || this.state.buffalo_liter_evening) ? 'true' : ''}
                                        quantityMessage={milkAnimalQuantity}
                                        rateValue={this.state.buffalo_rate}
                                        rateValidatorMessage={buffaloRateOptions}
                                    />}
                                </div>
                            </div>
                            <div className="col-md-12">
                                {this.props.validator.message(`milk_animal_${index}`, (this.state.milk_animal_buffalo || this.state.milk_animal_cow) ? 'true' : '', 'required', milkAnimalOptions)}
                            </div>
                        </div>
                    </div>
                </div>
                <SelectContactModal
                    isOpen={this.state.isOpenSelectContact}
                    onClose={this.hideSelectContact}
                    onSelect={this.onContactSelected}
                    contacts={this.state.contact.phoneNumbers || []} />
            </>
        );
    }
};

const mapDispatchToProps = {
    togglePremiumPopup
};

export default connect(null, mapDispatchToProps)(withLanguage(AddCustomerBox));
