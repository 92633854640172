import React from "react";
import { Switch } from "react-onsenui";
import { useMobile } from "src/components/library/detect-device";
import { useLanguage } from "src/components/language/LanguageController";

const MilkAnimalSetting = ({
  animalType,
  isBuyer,
  personalRateChecked,
  fixedBuyerChecked,
  onSwitchChange,
  isEdit,
  handleInput,
  validator,
  index,
  quantityValue,
  quantityMessage,
  rateValue,
  rateValidatorMessage,
  defaultValueMorning,
  defaultValueEvening,
  defaultValueRate,
}) => {
  const isMobile = useMobile();
  const { t } = useLanguage();
  
  // Define variable placeholders
  let switchName, inputNameMorning, inputNameEvening, inputNameRate;

  // Generate dynamic names based on context
  if (isEdit) {
    switchName = `auto_entry_config[${animalType}][enabled]`;
    inputNameMorning = `auto_entry_config[${animalType}][morning]`;
    inputNameEvening = `auto_entry_config[${animalType}][evening]`;
    inputNameRate = `rate[${animalType}]`;
  } else {
    switchName = `customers[${index}][auto_entry_config][${animalType}][enabled]`;
    inputNameMorning = `customers[${index}][auto_entry_config][${animalType}][morning]`;
    inputNameEvening = `customers[${index}][auto_entry_config][${animalType}][evening]`;
    inputNameRate = `customers[${index}][rate][${animalType}]`;
  }

  const renderInputs = () => (
    <div className="form-group col-12 mt-1 mb-0 d-flex justify-content-between">
      {fixedBuyerChecked && isBuyer && (
        <div className="d-flex float-left text-left p-0">
          <div className="p-0">
            <label>{t("text_morning")}</label>
            <input
              className="form-control add_customer_input"
              type="number"
              name={inputNameMorning}
              data-name={`${animalType}_liter_morning`}
              step="0.01"
              placeholder={t("text_milk")}
              onChange={handleInput}
              defaultValue={defaultValueMorning}
            />
            {validator.message(
              `${animalType}_liter_morning_${index}`,
              quantityValue,
              "required",
              quantityMessage
            )}
          </div>
          <div className="p-0 ml-2">
            <label>{t("text_evening")}</label>
            <input
              className="form-control add_customer_input"
              type="number"
              name={inputNameEvening}
              data-name={`${animalType}_liter_evening`}
              step="0.01"
              placeholder={t("text_milk")}
              onChange={handleInput}
              defaultValue={defaultValueEvening}
            />
          </div>
        </div>
      )}
      {(personalRateChecked || fixedBuyerChecked) && (
        <div className={`${fixedBuyerChecked ? "p-0 ml-2" : "col-12 p-0"}`}>
          <label>{t("text_rate")}</label>
          <input
            className="form-control add_customer_input"
            type="number"
            name={inputNameRate}
            data-name={`${animalType}_rate`}
            step="0.01"
            placeholder={t("text_rate")}
            onChange={handleInput}
            defaultValue={defaultValueRate}
          />
          {validator.message(
            `${animalType}_rate_${index}`,
            rateValue,
            "required|min:1,num",
            rateValidatorMessage
          )}
        </div>
      )}
    </div>
  );

  return (
    <>
      <div className={`${isBuyer && isMobile ? "col-md-12" : "col-10"}`}>
        <div className={`d-${isMobile ? "block" : "d-flex"} justify-content-between`}>
          <div className={`p-0 ml-0 ${isMobile ? "float-right text-right" : "col-4 float-right text-right"}`}>
            {t("text_personal_rate")} &nbsp;
            <Switch
              material="material"
              data-name={`personal_rate_${animalType}`}
              checked={personalRateChecked}
              onChange={onSwitchChange}
              disabled={fixedBuyerChecked}
            />
          </div>
          {isBuyer && (
            <div className={`p-0 ${isMobile ? "" : "col-5 float-right text-right"}`}>
              {t("text_fixed_buyer")} &nbsp;
              <Switch
                material="material"
                data-name={`fixed_buyer_${animalType}`}
                checked={fixedBuyerChecked}
                onChange={onSwitchChange}
                name={switchName}
                value="1"
              />
            </div>
          )}
        </div>
      </div>
      {(personalRateChecked || fixedBuyerChecked) && renderInputs()}
    </>
  );
};

export default MilkAnimalSetting;
