// @ts-nocheck
import React, { Component } from 'react';
import { toast, loader } from "../../library/util";
import { Page, Radio, Checkbox } from 'react-onsenui';
import SimpleValidator from '../../common/SimpleValidator';
import VerifyOtp from '../signin/VerifyOtp';
import withLanguage from '../../language/LanguageController';
import { errorHandler } from '../../library/response';
import analytics from '../../library/firebase/analytics';
import request from '../../library/request';
import ReferralCode from "./ReferralCode";
import { withRouter } from 'react-router';

class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitBtn: false,
      mobile: props.location?.state?.mobile,
      password: "",
      password_confirmation: ""
    };
    this.validator = new SimpleValidator();
  }

  componentDidMount () {
    analytics.setScreenName("Registration");
  }

  handleInput = (e) => {
    let target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;
    let disabledvalue = target.type === 'checkbox' ? target.checked : '';    
    let statedata = {
      [name]: value
    }
    if(name === "register_tc"){
      statedata["disabled"] = (disabledvalue) ? true : false
    }
    this.setState(statedata);        
  }
 
  async handleSubmit (event) {
    event.preventDefault();
    
    // validate form before submitting to server 
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate(); // rerender to show messages for the first time
      return false;
    }

    try {
      analytics.logEvent("register");
      loader.show(this.props.t("text_registering"));
      this.setState({ submitBtn: true });
      const formData = new FormData(document.getElementById("signupform"));
      formData.append('customer_id', this.props.customer_id);
      const { status, data, message }  = await request.post('/auth/signup', {
        withAuth: true,
        body: formData,
        version: "v2"
      });
      if (status) {
        toast(message);
        this.props.history.push({
          pathname: "/verifyOtp",
          state: {
            mobile: this.state.mobile,
            customer_id: data.customer_id,
            referral_type: data.referral ? data.referral.type : "",
          }
        });
      } else {
        throw message
      }
    } catch(error) {
      errorHandler(error);
    } finally {
      loader.hide();
      this.setState({submitBtn: false});
    }
  }

  render() {
    const { submitBtn } = this.state;
    const { t } = this.props;

    return (<Page className="page">
      <div className={`registration_header py-3 fixed-top`}>
        <h1 className="text-center text-white">{t("text_registration")}</h1>
      </div>
      <div className="signup_page mx-auto mb-2 max-width-500">
        <div className="bg-white p-3 rounded shadow-sm">
          <form method="POST" id="signupform" onSubmit={this.handleSubmit.bind(this)}>
              <div className="form-group row">
                <label className="col-md-12">{t("text_register_as")}</label>
                <div className="col-md-12">
                  <div className="form-check form-check-inline width150">
                    <Radio 
                      modifier="material form-check-input" 
                      name="customer_group_id"
                      value="1"
                      inputId="register_type_1"
                      onChange={this.handleInput}/>
                    <label 
                      className="form-check-label" htmlFor="register_type_1">
                        {t("text_kisan")}
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <Radio 
                      modifier="material form-check-input" 
                      name="customer_group_id"
                      value="2"
                      inputId="register_type_2"
                      onChange={this.handleInput}/>
                    <label className="form-check-label" htmlFor="register_type_2">
                      {t("text_dairy_owner")}
                    </label>
                  </div>
                  {this.validator.message('customer type', this.state.customer_group_id, 'required')}
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="name" className="sr-only">{t("text_name")}</label>
                <input 
                  className="form-control input-bottom-border"
                  type="text"
                  placeholder={t("text_enter_your_name")}
                  name="name"
                  id="name"
                  onChange={this.handleInput}/>
                {this.validator.message('name', this.state.name, 'required|alpha_num_space')}
              </div>
              <div className="form-group">
                <label htmlFor="mobile" className="sr-only">{t("text_mobile")}</label>
                <input 
                  className="form-control input-bottom-border"
                  type="tel"
                  placeholder={t("text_enter_mobile_number")}
                  name="mobile"
                  id="mobile"
                  defaultValue={this.state.mobile}
                  onChange={this.handleInput}/>
                {this.validator.message('r_mobil_number', this.state.mobile, 'required|numeric|size:10,string')}
              </div>
              <ReferralCode
                handleInput={this.handleInput} />
              <div className="form-group row">                
                <div className="col-md-12">
                    <div className="form-check form-check-inline">
                    <Checkbox 
                      modifier="material form-check-input"
                      inputId="register_tc"
                      onChange={this.handleInput}
                      name="register_tc"/>
                    <label className="form-check-label" htmlFor="register_tc" dangerouslySetInnerHTML={{
                      __html: t("text_terms_and_conditions_agreement")
                    }}/>
                  </div>  
                  {this.validator.message('term_and_conditions', this.state.register_tc, 'accepted')}                
                </div>
              </div>
              <div className="form-group mb-0">            
                <input 
                  className="btn btn-success btn-block"
                  disabled={submitBtn}
                  type="submit"
                  value={submitBtn ? t("text_registering") : t("text_register")}/>
              </div>
          </form>
        </div>
      </div>
    </Page>);
  }
}

Registration.defaultProps = {
  mobile: ""
};

export default withRouter(withLanguage(Registration));
