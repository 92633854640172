import React from "react";
import { useSelector } from "react-redux";
import { useLanguage } from "src/components/language/LanguageController";
import { getAddMilkHowToSelectCustomer } from "src/reducers/dairySettings";
import { MILK_ENTRY_BY_CODE, MILK_ENTRY_BY_NAME } from "src/_constants";
import ReactSelectBox from "src/components/core/ReactSelectBox";

const CustomerSelection = ({
  code,
  customerCodeRef,
  customerSelectRef,
  handleCodeChange,
  customers,
  selectedCustomer,
  customerChangeHandler,
  dairyCustomerId,
  validator = null,
}) => {
  const { t } = useLanguage();
  const howToSelectCustomer = useSelector(getAddMilkHowToSelectCustomer);

  return (
    <div className="form-row">
      <div className="col-3">
        <div className="form-group">
          <label htmlFor="customer_code">{t("text_code")}</label>
          <input
            className="form-control"
            type="number"
            id="customer_code"
            defaultValue={code}
            ref={customerCodeRef}
            onChange={(e) => {
              e.persist();
              handleCodeChange(e);
            }}
            readOnly={howToSelectCustomer === MILK_ENTRY_BY_NAME}
            tabIndex={howToSelectCustomer === MILK_ENTRY_BY_NAME && -1}
          />
        </div>
      </div>
      <div className="col">
        <div className="form-group">
          <label htmlFor="input_dairy_customer_id">
            {t("text_select_customer")}
          </label>
          <ReactSelectBox
            noOptionsMessage={() => t("text_no_options")}
            placeholder={t("text_select_customer") + "..."}
            options={customers}
            name="dairy_customer_id"
            inputId="input_dairy_customer_id"
            value={selectedCustomer}
            onChange={customerChangeHandler}
            isDisabled={howToSelectCustomer === MILK_ENTRY_BY_CODE}
            tabIndex={howToSelectCustomer === MILK_ENTRY_BY_CODE && -1}
            ref={customerSelectRef}
          />
          {validator.message("add_milk_customer", dairyCustomerId, "required")}
        </div>
      </div>
    </div>
  );
};

export default CustomerSelection;
