/* eslint-disable indent */
import { UPDATE_SETTINGS } from "../actions/dairySettings";
import DairySettingModel from "../components/models/DairySetting";
import defaultSetting from "./defaultDairySetting.json";
import {FETCH_DAIRY_PREFERENCE_SUCCESS, MILK_ENTRY_BY_BOTH} from "../_constants";
import { copyObject } from "../components/library/util";
const dairySettingModal = new DairySettingModel();
const dairySetting = dairySettingModal.get();
const initialState = {
    settings: dairySetting || defaultSetting,
    dairyPreference: {}
};
function dairyCustomerProduct(state = initialState, action) {
    switch (action.type) {
      case UPDATE_SETTINGS:
        var settings = {...state.settings, ...action.settings};
        DairySettingModel.save(settings); //save dairy detail into local db
        return {
          ...copyObject(state),
          settings: settings,
        };
     case FETCH_DAIRY_PREFERENCE_SUCCESS:
      return {
        ...copyObject(state),
        dairyPreference: action.data,
      };
      default:
          return state;
    }
}

export const getAddMilkHowToSelectCustomer = state => {
  return state.dairySettings?.dairyPreference?.add_milk_how_to_select_customer?.value || MILK_ENTRY_BY_BOTH
}

export const getMilkEntryAutoPrint = state => { 
  return state.dairySettings?.dairyPreference?.auto_print_on_milk_entry?.value == 1 ? true : false;
}

export default dairyCustomerProduct; 
