import React from "react";
import { ucFirst } from "./util";
import { t } from "../language/LanguageController";
import { checkFeatureInSubscription } from "../common/FeatureFlag/FeatureFlag";
import { MILK_ENTRY_BY_CODE } from "src/_constants";

export const getCustomersForSelect = customers => {
    if(customers?.length) {
        return customers.map(customer => ({
            ...customer,
            value: customer.dairy_customer_id,
            label: getCustomerLabel(customer),
            isOptionDisabled: true
        }));
    }

    return [];
};

export const formatCustomerForDropDown = getCustomersForSelect;

export const getSelectedCustomerForSelect = (customer) => {
    return {
        label: getCustomerLabel(customer),
        value: customer.dairy_customer_id
    };
};

export const getCustomerLabel = (customer) => 
    `${customer.code}-${customer.name} (${customer.mobile ? customer.mobile + " - " : ""}${ucFirst(customer.type)})`;


export const customerFilter = (filter = "") => {
    return (customer) => {
        filter = filter.trim();
        if(!filter) {
            return true;
        }
        filter = filter.toLowerCase();
        const dairyCustomerId = (customer.dairy_customer_id).toString().toLowerCase();
        const name = (customer.customer_name || customer.name || "").toLowerCase();
        const mobile = (customer.mobile || "").toLowerCase();
        const milkAnimal = (customer.milk_animals || "").toLowerCase();
        const code = (customer.code || "").toLowerCase();
        return dairyCustomerId.includes(filter)
            || name.includes(filter)
            || mobile.includes(filter)
            || milkAnimal.includes(filter)
            || code.includes(filter);
    };
};

export const getCustomerCodeError = (validator, code, existingCodes = []) => {
    let validations = "required|numeric|integer|min:1,num";
    if(existingCodes.length) {
        validations += `|not_in:${existingCodes.join(",")}`;
    }
    return validator.message(
        "customer_code",
        code,
        validations,
        {
            element: message => {
                return (
                    <div className="srv-validation-message">
                        {t(message).replace("{number}", existingCodes.join(","))}
                    </div>
                );
            },
            messages: {
                "required": "error_the_customer_code_required",
                "numeric": "error_the_customer_code_number",
                "integer": "error_the_customer_code_integer",
                "min": "error_the_customer_code_min",
                "not_in": "error_the_selected_customer_code_not_in",
            }
        }
    );
};

export const getDefaultSelectedCustomer = (customers, howToSelectCustomer) => {
    if (!customers.length) return null;
    let customer;

    if (howToSelectCustomer === MILK_ENTRY_BY_CODE) {
        const sortedCustomers = [...customers].sort((a, b) => parseInt(a.code, 10) - parseInt(b.code, 10));
        customer = sortedCustomers[0];
    } else {
        customer = customers[0];
    }

    return {
        ...customer,
        ...getCustomerForCollection(customer)
    };
}

export const getCustomerForCollection = (customer) => {
    const hasMultipleCattleFeature = checkFeatureInSubscription("multiple_cattle", false);
    const hasPersonalRateFeature = checkFeatureInSubscription("personal_rate", false)
    const milkAnimals = customer ? (customer.milk_animals).split(',') : [];
    const animalType = milkAnimals.length ? milkAnimals[0] : "";
    const personalRate = customer && hasPersonalRateFeature ? customer.rate : {};
    const dairyCustomerId = customer ? customer.dairy_customer_id : null;
    return {
        milkAnimals: hasMultipleCattleFeature ? milkAnimals : [animalType],
        cattle: animalType,
        personalRate,
        dairyCustomerId,
        code: customer ? customer.code : ""
    }
}

export const getCustomerById = (customers = [], dairyCustomerId) => {
    return customers.find(customer => customer.dairy_customer_id == dairyCustomerId) || {};
}