import React, { useState, useRef, useReducer } from "react";
import { Switch } from "react-onsenui";
import serializeForm from "form-serialize";
import { Button, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import IncrementDecrementSection from "./IncrementDecrementSection";
import { DEFAULT_STD_SNF, DEFAULT_STD_CLR, FAT_LOWER_LIMIT, FAT_UPPER_LIMIT } from "../../../../../_constants/rateList";
import { generateRandom } from "../../../../library/util";
import CLRFormula from "../../RateList_new/CLRFormula";
import SNFFormula from "../../RateList_new/SNFFormula";
import MilkRateByAnimal from "../../RateList_new/MilkRateByAnimal";
import { useStateCallback } from "../../../../../hooks";
import FixFATSNFRate from "./FixFATSNFRate";
import ErrorBoundary from "../../../../common/ErrorBoundary";
import FatSNFRatioRateList from "./FatSNFRatioRateList";
import { PrimaryButton } from "../../../../common/buttons/PrimaryButton";
import { useMobile } from "../../../../library/detect-device"; 
import Bonus from "./Bonus";
import MilkRateKgPerFat from "./MilkRateKgPerFat";

export default function CustomeRateListSlotModal({
    t,
    editSlot,
    slot = {},
    rateBy,
    isOpen, 
    fatStartFrom,
    onAdd,
    onUpdate,
    onClose,
    validator
}) {
    let slotForm = useRef();

    const getOptions = (start = 2.5) => {
        let options = [];
        for(let i = parseFloat(start); i<= 15; i += 0.1){
            options.push(<option key={i.toFixed(1)}>{i.toFixed(1)}</option>);
        }
        return options;
    };

    const [rateType, setRateType] = useStateCallback(editSlot && slot.rate_type ? slot.rate_type : "ts");
    const [isDeduction, setIsDeduction] = useState(editSlot && slot.is_deduction == "1" ? true : false);
    const [isIncrementByFat, setIsIncrementByFat] = useState(editSlot && slot.is_increment_by_fat == "1" ? true : false);
    const [fatFrom, setFatFrom] = useState(editSlot && slot.fat_rate ? slot.fat_rate.min : (fatStartFrom ? fatStartFrom : FAT_LOWER_LIMIT));
    const [fatTo, setFatTo] = useState(editSlot && slot.fat_rate ? slot.fat_rate.max : (fatStartFrom ? parseFloat(fatStartFrom) + 0.3 : 3.5));
    const [fatRate, setFatRate] = useState(editSlot && slot.fat_rate ? slot.fat_rate.base_rate : 290);
    const [snfRate, setSNFRate] = useState(editSlot && slot.fat_rate ? slot.fat_rate.snf_base_rate : 250);
    const showCLRSNFFormula = !["fix","animal"].includes(rateType);
    const showFixFATSNFRate = !["animal"].includes(rateType);
    const showFatRate = !["animal"].includes(rateType);
    const showIncPerFat = !["animal"].includes(rateType);
    const showIncDec = !["animal"].includes(rateType) && ["snf", "clr"].includes(rateBy);
    const showRateByMilk = ["animal"].includes(rateType);
    let isFatSnfRatio = editSlot ? true : false;
    if(editSlot && slot.fat_rate && typeof slot.fat_rate.is_fat_snf_ratio !== "undefined") {
        isFatSnfRatio = slot.fat_rate.is_fat_snf_ratio == 1 ? true : false;
    }
    const [isFatSnfRatioEnabled, setIsFatSnfRatioEnabled] = useState(isFatSnfRatio);
    const [fatRateRatio, setFatRateRatio] = useState(editSlot && slot.fat_rate ? slot.fat_rate.base_rate_ratio : 60);

    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    const addUpdateSlot = event => {
        event.preventDefault();
        if (!validator.allValid()) {
            console.log("Error in custom rate list add slot form");
            validator.showMessages();
            const errorMessages = validator.errorMessages;
            let otherError = false, incrementTabError = false, decrementTabError = false;
            for(const errorMessageField in errorMessages) {
                if((errorMessageField === "increment_clr_rate" || errorMessageField === "increment_snf_rate") 
                    && errorMessages[errorMessageField] != null) {
                    incrementTabError = true;
                } else if((errorMessageField === "decrement_clr_rate" || errorMessageField === "decrement_snf_rate") 
                    && errorMessages[errorMessageField] != null) {
                    decrementTabError = true;
                } else if(errorMessages[errorMessageField] != null){
                    otherError = true;
                }
            }
            
            const decrementTab = document.querySelector("a[href=\"#snf_rate_deduction\"]") || document.querySelector("a[href=\"#clr_rate_deduction\"]");
            const incrementTab = document.querySelector("a[href=\"#snf_rate_increment\"]") || document.querySelector("a[href=\"#clr_rate_increment\"]");

            // If error in increment/decrement section and no other error then click to increment/decrement tab
            if(otherError === false && (incrementTabError === true || decrementTabError === true)) {
                // if decrement tab is active and increment tab have eror and decrement tab does not have error then click to increment tab
                if(decrementTab.classList.contains("active") 
                    && incrementTabError === true
                    && decrementTabError === false){
                    incrementTab.click();
                } else if(incrementTab.classList.contains("active") 
                    && incrementTabError === false 
                    && decrementTabError === true){
                    // else if increment tab is active and decrement tab have error and increment tab does not have error then click to decrement tab
                    decrementTab.click();
                }
            }
            forceUpdate();
            return false;
        } else {
            const slotFormData = serializeForm(slotForm.current, { 
                hash: true 
            });

            if(editSlot) {
                onUpdate(slotFormData);
            } else {
                onAdd(slotFormData);
            }
        }
    };

    const CLRSNFFormula = ["clr","fat"].includes(rateBy) ? SNFFormula : CLRFormula;
    let clrSnfFormulaValue;
    if(rateBy === "clr") {
        clrSnfFormulaValue = {
            snfFormula: editSlot && slot?.snf_formula ? slot?.snf_formula : {}
        }
    } else {
        clrSnfFormulaValue = {
            clrFormula: editSlot && slot?.clr_formula ? slot?.clr_formula : {}
        }
    }

    const isMobile = useMobile();

    return (
        <ErrorBoundary>
            <Dialog
                open={isOpen}
                onClose={onClose}
                maxWidth="md"
                fullScreen={isMobile}
                fullWidth
                aria-labelledby="form-dialog-title"
                scroll="paper"
                className="rate-list-modal-dialog">
                <MuiDialogTitle id="form-dialog-title">
                    {editSlot ? t("text_edit_slot") : t("text_add_slot")}
                    <IconButton aria-label="close" className="btn-modal-close" onClick={onClose}>
                        <CloseIcon />   
                    </IconButton>
                </MuiDialogTitle>
                <DialogContent dividers>
                    <form method="post" ref={slotForm}>
                        <input type="hidden" name="slot_id" defaultValue={slot.slot_id || generateRandom(3)} />
                        <div className="form-group">
                            <label>{t("text_rate_formula")}</label>
                            <select className="form-control" 
                                name="rate_type"
                                value={rateType}
                                onChange={e => setRateType(e.target.value, () => validator.purgeFields())}>
                                <option value="ts">{t("text_by_ts")}</option>
                                <option value="fat_snf_ratio">{t("text_by_fat_snf_ratio")}</option>
                                <option value="feq">{t("text_by_feq")}</option>
                                <option value="direct">{t("text_by_direct")}</option>
                                <option value="fix">{t("text_by_fix")}</option>
                            </select>
                            {validator.message("rate_formula", rateType, "required")}
                        </div>
                        {showCLRSNFFormula && <CLRSNFFormula {...clrSnfFormulaValue}/>}
                        {showFixFATSNFRate && <>
                            <hr className="mb-16" />
                            <FixFATSNFRate
                                fixSNFCLR={editSlot && slot.fix_snf_clr ? slot.fix_snf_clr : {}}
                                rateBy={rateBy}
                            />
                        </>}
                        {showFatRate && <>
                            <hr className="mb-16" />
                            <div className="form-group">
                                <div className="form-row">
                                    <div className={`${rateType === "fat_snf_ratio" ? "col-12" : "col-7"}`}>
                                        <label className="control-label block text-right pr-30 pl-2">
                                            <span className="pull-left">{t("text_from")}</span> 
                                            {t("text_to")}
                                        </label>
                                        <div className="input-group">
                                            <select 
                                                className="form-control milk_rate_from_to"
                                                name={"fat_rate[min]"}
                                                data-name="from_fat"
                                                value={fatFrom}
                                                onChange={e => setFatFrom(e.target.value)}>
                                                {getOptions()}
                                            </select>
                                            <span className="input-group-prepend input-group-append">
                                                <span className="input-group-text px-1">
                                                    -
                                                </span>
                                            </span>
                                            <select 
                                                className="form-control milk_rate_from_to"
                                                name={"fat_rate[max]"}
                                                data-name="to_fat"
                                                value={fatTo}
                                                onChange={e => setFatTo(e.target.value)}>
                                                {getOptions(parseFloat(fatFrom) + 0.1)}
                                            </select>
                                        </div>
                                        {validator.message("fat_from", fatFrom, `required|numeric|min:${FAT_LOWER_LIMIT},num|max:${FAT_UPPER_LIMIT},num`)}
                                        {validator.message("fat_to", fatTo, `required|numeric|min:${parseFloat(fatFrom) + 0.1},num|max:${FAT_UPPER_LIMIT},num`)}
                                    </div>
                                    {rateType !== "fat_snf_ratio" ? (
                                        <div className="col-5 pl-0">
                                            <label className="control-label block">{t("text_base_rate")}</label>
                                            <div className="input-group">
                                                <input 
                                                    type="number"
                                                    name={"fat_rate[base_rate]"}
                                                    step="any"
                                                    className="form-control"
                                                    value={fatRate}
                                                    onChange={e => setFatRate(e.target.value)}/>
                                                <span className="input-group-append">
                                                    <span className="input-group-text">/ {t("text_kg")}</span>
                                                </span>
                                            </div>
                                            {validator.message("fat_rate", fatRate, "required|numeric")}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </>}
                        {rateType === "fat_snf_ratio" &&  <>
                            <hr className="mb-16" />
                            <FatSNFRatioRateList
                                fatRate={fatRate}
                                setFatRate={setFatRate}
                                validator={validator}
                                snfRate={snfRate}
                                setSNFRate={setSNFRate}
                                fatRateRatio={fatRateRatio}
                                setFatRateRatio={setFatRateRatio}
                                isFatSnfRatioEnabled={isFatSnfRatioEnabled}
                                setIsFatSnfRatioEnabled={setIsFatSnfRatioEnabled}
                            />
                        </>}
                        {showRateByMilk && 
                            <MilkRateByAnimal />}
                        {showIncPerFat && <>
                            <hr className="mb-16" />
                            <div className={`form-group ${isIncrementByFat ? "mb-1" : ""}`}>
                                <div className="form-row">
                                    <div className="col-8 lheight-24">
                                        <label className="mb-0">{t("text_increment_by_fat")}</label>
                                    </div>
                                    <div className="col-4 text-right">
                                        <Switch
                                            modifier="material"
                                            checked={isIncrementByFat}
                                            onChange={e => {
                                                validator.purgeFields();
                                                setIsIncrementByFat(e.target.checked);
                                            }}/>
                                        <input type="hidden" name="is_increment_by_fat" value={isIncrementByFat == true ? 1 : 0} readOnly/>
                                    </div>
                                </div>
                            </div>
                        </>}
                        {isIncrementByFat ? (
                            <div className="form-group">
                                <div className="input-group">
                                    <input 
                                        type="number"
                                        min="0"
                                        name="increment_by_fat"
                                        className="form-control"
                                        defaultValue={slot.increment_by_fat || 0} />
                                    <select 
                                        className="form-control"
                                        name={"increment_by_fat_type"}
                                        value={slot.increment_by_fat_type}
                                        readOnly>
                                        <option value="fixed">{t("text_paise")}</option>
                                        <option value="percent">{t("text_percent")}</option>
                                    </select>
                                </div>
                            </div>
                        ) : null}
                        {rateType === "direct" && (
                        <>
                            <hr className="mb-16" />
                            <MilkRateKgPerFat isKgPerFat={slot.is_kg_per_fat} />
                        </>
                        )}
                        <hr className="mb-16" />
                        <Bonus
                            isBonus={slot.is_bonus || 0}
                            bonusAmount={slot.bonus_amount || 0}
                        />
                        {showIncDec && <>
                            <hr className="mb-16" />
                            <div className="form-group clearfix mb-2">
                                <div className="form-row">
                                    <div className="col-8">
                                        <label>{t("text_increment")} / {t("text_deduction")}</label>
                                    </div>
                                    <div className="col-4 text-right">
                                        <Switch
                                            modifier="material" 
                                            checked={isDeduction}
                                            onChange={e => {
                                                validator.purgeFields();
                                                setIsDeduction(e.target.checked);
                                            }}
                                            />
                                        <input type="hidden" name="is_deduction" value={isDeduction == true ? 1 : 0} readOnly/>
                                    </div>
                                </div>
                            </div>
                        </>}
                        {isDeduction && <IncrementDecrementSection 
                            t={t}
                            fatFrom={fatFrom}
                            std_snf={editSlot && slot.std_snf ? slot.std_snf : DEFAULT_STD_SNF}
                            std_clr={editSlot && slot.std_clr ? slot.std_clr : DEFAULT_STD_CLR}
                            increment_decrement_by={editSlot && slot.increment_decrement_by ? slot.increment_decrement_by : "clr"}
                            increments={editSlot && slot.increment ? slot.increment : null}
                            decrements={editSlot && slot.decrement ? slot.decrement : null}
                            deduction_on={editSlot && slot.deduction_on ? slot.deduction_on : "calculated_rate"}
                            increment_on={editSlot && slot.increment_on ? slot.increment_on : "calculated_rate"}
                            incDecDeductionType={slot?.inc_dec_deduction_type || "by_slot"}
                            incDecIncrementType={slot?.inc_dec_increment_type || "by_slot"}
                            rateFormula={rateType}
                            validator={validator}
                            {...slot}
                        />}
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="button"
                        onClick={onClose}
                        className="btn">
                        {t("text_cancel")}
                    </Button>
                    <PrimaryButton
                        variant="contained"
                        onClick={addUpdateSlot}
                        type="submit"
                    >
                        {t("text_ok")}
                    </PrimaryButton>
                </DialogActions>
            </Dialog>
        </ErrorBoundary>
    );
}