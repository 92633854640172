import { ucFirst, cleanString, currencyFormat } from "./util";
import moment from "moment";
import config from "../../config/config";
import { t, getLang } from "../language/LanguageController";
import { getBillingStartEndDate } from "./dateUtils";

export const todayMilkTemplate = (milk) => {
  let message = "";
  if (!milk.length) {
    return null;
  }
  milk.forEach((row) => {
    if (row.liter_0 > 0 || row.liter_1 > 0) {
      let date = moment(row.date).format(config.sms_date_format);
      message += `${date} - ${ucFirst(t("text_" + row.milk_type))}\n`;
      if (row.liter_0 > 0) {
        message += t("text_morning") + ":\n";
        message += `${t("text_liter")}: ${row.liter_0}  ${t("text_fat")}: ${row.fat_0}  ${t("text_clr")}: ${row.clr_0}\n${t("text_rate")}: ${row.rate_0}  ${t("text_total")}: ${row.total_0}\n`;
      }
      if (row.liter_1 > 0) {
        message += `${t("text_evening")}:\n`;
        message += `${t("text_liter")}: ${row.liter_1}  ${t("text_fat")}: ${row.fat_1}  ${t("text_clr")}: ${row.clr_1}\n${t("text_rate")}: ${row.rate_1}  ${t("text_total")}: ${row.total_1}\n`;
      }
    }
  });

  return message;
};

export const milkTotalTemplate = (
  name,
  milkTotal,
  totalAmount,
  avgMilk,
  avgAmount,
  avgRate
) => {
  const lang = getLang();
  let message = "";
  if (lang === "hi") {
    message += `${cleanString(name)} ${t("text_of")} ${t("text_milk_total")}\n`;
  } else {
    message += `${cleanString(name)}'s ${t("text_milk_total")}\n`;
  }
  message += `${t("text_total_milk")}: ${milkTotal}\n`;
  message += `${t("text_total_amount")}: ${totalAmount}\n`;
  message += `${t("text_avg_milk")}: ${avgMilk}\n`;
  message += `${t("text_avg_amount")}: ${avgAmount}\n`;
  message += `${t("text_avg_rate")}: ${avgRate}\n`;
  return message;
};

export const customerBillTemplate = ({ name, code }, data = {}) => {
  const billDate = getBillDateForBillingCycle(
    data?.billingCycle,
    data?.startDate
  );
  let message = `${code} - ${cleanString(name)}, ${t("text_your_milk_bill_for").replace("{bill_date}", billDate)}:\n`;
  message += `${t("text_total_milk")}: ${data?.milk_detail?.total?.total_milk}${t("text_l")}\n`;
  message += `${t("text_rate")}: ${currencyFormat(data?.milk_detail?.total?.avg_rate)}\n`;
  message += `${t("text_milk_amount")}: ${currencyFormat(data.milk_amount)}\n`;
  message += `${t("text_last_due")}: ${currencyFormat(data.last_due)}\n`;
  message += `${t("text_product_purchase")}: ${currencyFormat(data.product_amount)}\n`;
  message += `${t("text_advance")}: ${currencyFormat(data.advance_payment)}\n`;
  message += `${t("text_payable_amount")}: ${currencyFormat(data.payable_amount)}\n`;
  message += `${t("text_net_payable")}: ${currencyFormat(data.net_payable)}\n`;
  message += `${t("text_transferred_to_loan")}: ${currencyFormat(data.transferd_to_loan)}\n`;
  message += `${t("text_cash_recovered")}: ${currencyFormat(data.cash_recovered)}\n`;
  message += `${t("text_paid_amount")}: ${currencyFormat(data.paid_amount)}\n`;
  message += `${t("text_remaining")}: ${currencyFormat(data.remaining)}\n`;
  return message;
};

export const customerLoanTemplate = (
  name,
  date,
  sanctioned,
  recovered,
  balance
) => {
  const lang = getLang();
  let message = "";
  if (lang === "hi") {
    message += `${cleanString(name)} ${t("text_of")} ${date}: ${t("text_loan_on")}\n`;
  } else {
    message += `${cleanString(name)}'s Loan on ${date}:\n`;
  }
  message += `${t("text_total_sanctioned")}: ${sanctioned}\n`;
  message += `${t("text_recovered")}: ${recovered}\n`;
  message += `${t("text_balance")}: ${balance}\n`;
  return message;
};

export const productsTemplate = (name, products, amount, qty) => {
  const lang = getLang();
  let message = "";
  if (lang === "hi") {
    message += `${cleanString(name)} ${t("text_of1")} ${t("text_product_detail")}\n`;
  } else {
    message += `${cleanString(name)}'s Products Detail:\n`;
  }
  message += `${t("text_quantity")}: ${qty}\n`;
  message += `${t("text_amount")}: ${amount}\n`;
  if (products && products.length) {
    message += `${t("text_date")} | ${t("text_product")} | ${t("text_qty")} | ${t("text_price")} | ${t("text_total")}\n`;
    for (const product of products) {
      message += `${moment(product.date).format(config.display_date_format)} | ${product.name} | ${product.quantity} | ${product.price} | ${product.total}\n`;
    }
  }
  return message;
};

export const advanceTemplate = (name, advance) => {
  const lang = getLang();
  let message = "";
  if (lang === "hi") {
    message += `${cleanString(name)} ${t("text_of")} ${t("text_advance_payment")}\n`;
  } else {
    message += `${cleanString(name)}'s Advance Payment Detail:\n`;
  }
  if (advance && advance.length) {
    message += `${t("text_date")} | ${t("text_amount")} | ${t("text_remark")}\n`;
    for (const row of advance) {
      message += `${moment(row.date).format(config.display_date_format)} | ${row.amount} | ${row.remark}\n`;
    }
  }
  return message;
};

export const milkSMSTemplate = (
  {
    name,
    date,
    code,
    cattle,
    type,
    shift,
    liter,
    fat,
    clr,
    snf,
    rate,
    total,
    billTotal,
  },
  dairyPreference = null
) => {
  let msgText = `${code} - ${name} - ${ucFirst(t("text_" + type))}\n`;
  msgText += `${date.format(config.display_date_format)} - ${t("text_" + shift)} - ${t("text_" + cattle)}\n`;
  msgText += `${t("text_liter")}: ${currencyFormat(liter, false)}\n`;
  if (fat) {
    msgText += `${t("text_fat")}: ${fat}\n`;
  }
  if (clr) {
    msgText += `${t("text_clr")}: ${clr ? clr : ""}\n`;
  }
  if (snf) {
    msgText += `${t("text_snf")}: ${snf ? snf : ""}\n`;
  }
  msgText += `${t("text_rate")}: ${currencyFormat(rate)}\n`;
  msgText += `${t("text_total")}: ${currencyFormat(total)}\n`;
  if (dairyPreference?.bill_amount_in_msg?.value == 1) {
    msgText += `${t("text_total_milk")}: ${billTotal?.total_milk} ${t("text_ltr")}\n`;
    msgText += `${t("bill_amount")}: ${currencyFormat(billTotal?.total_amount)}`;
  }
  return msgText;
};

export function getBillDateForBillingCycle(billingCycle, date) {
  if (billingCycle === "monthly") {
    return date.format("MMM, YYYY");
  }

  const billingDates = getBillingStartEndDate(billingCycle, date);
  return (
    billingDates.startDate.format("DD, MMM") +
    " - " +
    billingDates.endDate.format("DD, MMM, YYYY")
  );
}
