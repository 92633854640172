/* eslint-disable no-undef */
// @ts-nocheck
/* eslint-disable no-console */

import {
  checkAndrequestPermission,
  hasPermission,
  listPermissions,
  requestPermission,
} from "./androidPermissions";

const printers = [
  {
    name: "BTP-2B08361",
    address: "00:04:3E:6D:2B:85",
    id: "00:04:3E:6D:2B:85",
    class: 1028,
  },
  {
    name: "TV",
    address: "EC:FA:5C:60:B3:1C",
    id: "EC:FA:5C:60:B3:1C",
    class: 1060,
  },
  {
    name: "Bluetooth music",
    address: "B1:E4:AD:78:EE:AF",
    id: "B1:E4:AD:78:EE:AF",
    class: 1028,
  },
  {
    name: "JBL GO",
    address: "30:C0:1B:A9:2E:EB",
    id: "30:C0:1B:A9:2E:EB",
    class: 1028,
  },
  {
    name: "Boult Curve",
    address: "A7:19:01:87:8C:AA",
    id: "A7:19:01:87:8C:AA",
    class: 1028,
  },
  {
    name: "Bluetooth music",
    address: "FC:58:FA:EE:7D:50",
    id: "FC:58:FA:EE:7D:50",
    class: 1028,
  },
  {
    name: "gogeta",
    address: "00:0E:8E:8B:31:B5",
    id: "00:0E:8E:8B:31:B5",
    class: 260,
  },
];

export const printersList = () => {
  return new Promise(async (resolve, reject) => {
    if (typeof bluetoothSerial === "undefined") {
      resolve(printers);
    } else {
      try {
        let permissionGranted = true;
        if (device.version > 11) {
          permissionGranted = checkAndrequestPermission(
            listPermissions().BLUETOOTH_SCAN
          );
          const ifHasPermission = await hasPermission(
            listPermissions().BLUETOOTH_SCAN
          );
          console.log(
            "printersList hasPermission permissions.BLUETOOTH_SCAN",
            ifHasPermission
          );
          permissionGranted = ifHasPermission;
          if (!ifHasPermission) {
            permissionGranted = await requestPermission(
              listPermissions().BLUETOOTH_SCAN
            );
            console.log(
              "printersList permissionGranted permissions.BLUETOOTH_SCAN",
              permissionGranted
            );
          }
        }
        if (permissionGranted) {
          // eslint-disable-next-line no-undef
          bluetoothSerial.list(
            (devices) => {
              console.log("Device list success", devices);
              resolve(devices);
            },
            (error) => {
              console.log("Device list failure", error);
              reject(error);
            }
          );
        }
      } catch (error) {
        console.log("printersList failure", error);
        reject(error);
      }
    }
  });
};

export const isBTEnabled = () => {
  return new Promise(async (resolve, reject) => {
    if (typeof bluetoothSerial !== "undefined") {
      try {
        let permissionGranted = true;
        if (device.version > 11) {
          permissionGranted = false;
          const ifHasPermission = await hasPermission(
            listPermissions().BLUETOOTH_CONNECT
          );
          console.log(
            "isBTEnabled hasPermission permissions.BLUETOOTH_CONNECT",
            ifHasPermission
          );
          permissionGranted = ifHasPermission;
          if (!ifHasPermission) {
            permissionGranted = await requestPermission(
              listPermissions().BLUETOOTH_CONNECT
            );
            console.log(
              "isBTEnabled permissionGranted permissions.BLUETOOTH_CONNECT",
              permissionGranted
            );
          }
        }
        if (permissionGranted) {
          // eslint-disable-next-line no-undef
          bluetoothSerial.isEnabled(
            (data) => {
              console.log("Device is enable success", data);
              resolve(true);
            },
            (error) => {
              console.log("Device is enable failure", error);
              resolve(false);
            }
          );
        }
      } catch (error) {
        console.log("isBTEnabled failure", error);
        reject(error);
      }
    } else {
      resolve(false);
    }
  });
};

export const enableBluetooth = () => {
  return new Promise(async (resolve, reject) => {
    if (typeof bluetoothSerial !== "undefined") {
      try {
        let permissionGranted = true;
        if (device.version > 11) {
          permissionGranted = false;
          const ifHasPermission = await hasPermission(
            listPermissions().BLUETOOTH_CONNECT
          );
          console.log(
            "enableBluetooth hasPermission permissions.BLUETOOTH_CONNECT",
            ifHasPermission
          );
          permissionGranted = ifHasPermission;
          if (!ifHasPermission) {
            permissionGranted = await requestPermission(
              listPermissions().BLUETOOTH_CONNECT
            );
            console.log(
              "enableBluetooth permissionGranted permissions.BLUETOOTH_CONNECT",
              permissionGranted
            );
          }
        }
        if (permissionGranted) {
          // eslint-disable-next-line no-undef
          bluetoothSerial.enable(
            (data) => {
              console.log("Device enable success", data);
              resolve(true);
            },
            (error) => {
              console.log("Device enable failure", error);
              reject(false);
            }
          );
        }
      } catch (error) {
        console.log("enableBluetooth failure", error);
        reject(error);
      }
    } else {
      resolve(true);
    }
  });
};

export const scanDevices = () => {
  return new Promise(async (resolve, reject) => {
    if (typeof bluetoothSerial !== "undefined") {
      try {
        let permissionGranted = true;
        if (device.version > 11) {
          permissionGranted = false;
          const ifHasPermission = await hasPermission(
            listPermissions().BLUETOOTH_SCAN
          );
          console.log(
            "scanDevices hasPermission permissions.BLUETOOTH_SCAN",
            ifHasPermission
          );
          permissionGranted = ifHasPermission;
          if (!ifHasPermission) {
            permissionGranted = await requestPermission(
              listPermissions().BLUETOOTH_SCAN
            );
            console.log(
              "scanDevices permissionGranted permissions.BLUETOOTH_SCAN",
              permissionGranted
            );
          }
        }
        if (permissionGranted) {
          // eslint-disable-next-line no-undef
          bluetoothSerial.discoverUnpaired(
            (devices) => {
              console.log("Devices scanned success", devices);
              resolve(devices);
            },
            (error) => {
              console.log("Devices scan failure", error);
              reject(error);
            }
          );
        }
      } catch (error) {
        console.log("enableBluetooth failure", error);
        reject(error);
      }
    }
  });
};

export const isConnected = () => {
  return new Promise(async (resolve, reject) => {
    if (typeof bluetoothSerial !== "undefined") {
      try {
        let permissionGranted = true;
        if (device.version > 11) {
          permissionGranted = false;
          const ifHasPermission = await hasPermission(
            listPermissions().BLUETOOTH_CONNECT
          );
          console.log(
            "isConnected hasPermission permissions.BLUETOOTH_CONNECT",
            ifHasPermission
          );
          permissionGranted = ifHasPermission;
          if (!ifHasPermission) {
            permissionGranted = await requestPermission(
              listPermissions().BLUETOOTH_CONNECT
            );
            console.log(
              "isConnected permissionGranted permissions.BLUETOOTH_CONNECT",
              permissionGranted
            );
          }
        }
        if (permissionGranted) {
          isBTEnabled()
            .then((isEnabled) => {
              if (isEnabled) {
                // eslint-disable-next-line no-undef
                bluetoothSerial.isConnected(
                  (data) => {
                    console.log("Connected success", data);
                    resolve(true);
                  },
                  (error) => {
                    console.log("Connected failure", error);
                    resolve(false);
                  }
                );
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
              console.log("Error", error);
            })
            .finally(() => {
              resolve(false);
            });
        }
      } catch (error) {
        console.log("isConnected failure", error);
        reject(error);
      }
    } else {
      resolve(true);
    }
  });
};

export const connectDevice = (deviceAddress) => {
  return new Promise(async (resolve, reject) => {
    if (typeof bluetoothSerial !== "undefined") {
      try {
        let permissionGranted = true;
        if (device.version > 11) {
          permissionGranted = false;
          const ifHasPermission = await hasPermission(
            listPermissions().BLUETOOTH_CONNECT
          );
          console.log(
            "connectDevice hasPermission permissions.BLUETOOTH_CONNECT",
            ifHasPermission
          );
          permissionGranted = ifHasPermission;
          if (!ifHasPermission) {
            permissionGranted = await requestPermission(
              listPermissions().BLUETOOTH_CONNECT
            );
            console.log(
              "connectDevice permissionGranted permissions.BLUETOOTH_CONNECT",
              permissionGranted
            );
          }
        }
        if (permissionGranted) {
          isBTEnabled()
            .then((isEnabled) => {
              if (!isEnabled) {
                reject(false);
                return false;
              }
              bluetoothSerial.connect(
                deviceAddress,
                (data) => {
                  window.localStorage.setItem(
                    "last_connected_printer",
                    deviceAddress
                  );
                  resolve(true);
                },
                (error) => {
                  reject(new Error(error));
                }
              );
            })
            .catch((error) => {
              reject(error);
            });
        }
      } catch (error) {
        console.log("connectDevice failure", error);
        reject(error);
      }
    } else {
      reject(true);
    }
  });
};

export const connectLastPrinter = () => {
  return new Promise(async (resolve, reject) => {
    if (typeof bluetoothSerial !== "undefined") {
      try {
        let permissionGranted = true;
        if (device.version > 11) {
          permissionGranted = false;
          const ifHasPermission = await hasPermission(
            listPermissions().BLUETOOTH_CONNECT
          );
          console.log(
            "connectLastPrinter hasPermission permissions.BLUETOOTH_CONNECT",
            ifHasPermission
          );
          permissionGranted = ifHasPermission;
          if (!ifHasPermission) {
            permissionGranted = await requestPermission(
              listPermissions().BLUETOOTH_CONNECT
            );
            console.log(
              "connectLastPrinter permissionGranted permissions.BLUETOOTH_CONNECT",
              permissionGranted
            );
          }
        }
        if (permissionGranted) {
          isBTEnabled().then((isEnabled) => {
            if (isEnabled) {
              isConnected()
                .then((connected) => {
                  if (!connected) {
                    let deviceAddress = window.localStorage.getItem(
                      "last_connected_printer"
                    );
                    if (deviceAddress) {
                      connectDevice(deviceAddress).catch(console.log);
                      resolve(true);
                    }
                  }
                })
                .catch((error) => {
                  console.log("Check last connected printer", error);
                });
            }
          });
        }
      } catch (error) {
        console.log("connectLastPrinter failure", error);
        reject(error);
      }
    } else {
      reject("bluetoothSerial not supported");
    }
  });
};

export const printText = (textToWrite) => {
  return new Promise((resolve, reject) => {
    if (typeof bluetoothSerial !== "undefined") {
      // eslint-disable-next-line no-undef
      bluetoothSerial.write(
        textToWrite,
        (data) => {
          console.log("Write successfully", data);
          resolve(true);
        },
        (error) => {
          console.log("Write failure", error);
          reject(error);
        }
      );
    }
  });
};

export const printImageBase64 = (str) => {
  return new Promise((resolve, reject) => {
    if (typeof bluetoothSerial !== "undefined") {
      // eslint-disable-next-line no-undef
      bluetoothSerial.writeImageBase64(
        str,
        (data) => {
          console.log("Write successfully", data);
          resolve(true);
        },
        (error) => {
          console.log("Write failure", error);
          reject(error);
        }
      );
    }
  });
};

export const androidPrintBase64Image = printImageBase64;
