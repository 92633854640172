// @ts-nocheck
import React, { useEffect, useState } from "react";
import analytics from "../../../library/firebase/analytics";
import PropTypes from "prop-types";
import ModalDialog from "../../../common/ModalDialog";
import { PrimaryButton } from "../../../common/buttons/PrimaryButton";
import { Button } from "@material-ui/core";
import { currencyFormat } from "src/components/library/util";

const CustomerMilkPaymentModal = ({ t, validator, dueAmount, loanBalance, isOpen, onClose, onSubmit, submitBtn }) => {

    const [amount, setAmount] = useState(dueAmount);
    const [loanAmount, setLoanAmount] = useState();
    const [remainingAmount, setRemainingAmount] = useState(0);

    const calculateRemainingAmount = (newAmount, newLoanAmount) => {
        const leftAmount = dueAmount - newAmount - newLoanAmount;
        setRemainingAmount(parseFloat(leftAmount));
    };

    const milkBillAmount = (value) => {
        const newAmount = parseFloat(value);
        setAmount(newAmount);
        calculateRemainingAmount(newAmount || 0, loanAmount || 0);
    };
    
    const milkBillLoanAmount = (value) => {
        const newLoanAmount = parseFloat(value);
        setLoanAmount(newLoanAmount);
        calculateRemainingAmount(amount || 0, newLoanAmount || 0);
    };    

    useEffect(() => {
        analytics.logEvent("CustomerMilkPaymentModal");
    });

    const amountHasError = validator.message("amount", amount, `required|min:0,num|max:${dueAmount},num`);
    const loanHasError = validator.message("loan", loanAmount || 0, `num|max:${loanBalance},num`);

    return (
        <ModalDialog
            isOpen={isOpen}
            title={t("text_milk_payment")}
            form={{
                id: "milk_bill_payment_form",
                onSubmit
            }}
            dividers={false}
            content={
                <div className="mt-n3">
                    <div className="form-row">
                        <table className="table table-sm font-size-85r">
                            <tbody>
                                <tr>
                                    <td>{t("text_payable_amount")}</td>
                                    <td className="text-right">{currencyFormat(dueAmount)}</td>
                                </tr>
                                {loanBalance > 0 && <tr>
                                    <td>{t("text_loan")}</td>
                                    <td className="text-right">{currencyFormat(loanBalance)}</td>
                                </tr>}
                                <tr>
                                    <td>{t("amount_to_pay")}</td>
                                    <td className="text-right">
                                        <input
                                            id="customer_bill_amount_to_pay"
                                            name="amount"
                                            type="number"
                                            className={`text-right width80 ${Boolean(amountHasError) && "is-invalid"}`}
                                            value={amount}
                                            step="0.01"
                                            onChange={e => milkBillAmount(e.target.value)}
                                        />
                                        {amountHasError}
                                    </td>
                                </tr>
                                {loanBalance > 0 && <tr>
                                    <td>{t("amount_to_be_deposited_in_loan")}</td>
                                    <td className="text-right">
                                        <input
                                            id="customer_bill_amount_for_loan"
                                            name="loanAmount"
                                            type="number"
                                            className={`text-right width80 ${Boolean(amountHasError) && "is-invalid"}`}
                                            step="0.01"
                                            value={loanAmount}
                                            onChange={e => milkBillLoanAmount(e.target.value)}
                                            max={dueAmount - amount}
                                        />
                                        {loanHasError}
                                    </td>
                                </tr>}
                                <tr>
                                    <td>{t("text_remaining")}</td>
                                    <td className={remainingAmount < 0 ? "text-red text-right" : "text-right"}>{currencyFormat(Number(remainingAmount))}
                                        <div className={remainingAmount < 0 ? "text-right" : "d-none"}>
                                            {t("text_amount_should_not_exist_payable_amount")}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="form-row">
                        <div className="col-12">
                            <div className="form-group mb-0">
                                <label htmlFor="customer_bill_payment_remark">{t("text_remark")}</label>
                                <textarea
                                    id="customer_bill_payment_remark"
                                    name="remark"
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
            dialogActions={
                <>
                    <Button onClick={onClose} color="primary">
                        {t("text_cancel")}
                    </Button>
                    <PrimaryButton
                        disabled={remainingAmount < 0 || submitBtn}
                        type="submit"
                    >
                        {t("text_pay")}
                    </PrimaryButton>
                </>
            }
        />
    );
};

CustomerMilkPaymentModal.propTypes = {
    t: PropTypes.func,
    validator: PropTypes.object,
    dueAmount: PropTypes.number,
    loanBalance: PropTypes.number,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    submitBtn: PropTypes.bool
};

export default CustomerMilkPaymentModal;
